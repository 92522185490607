import { Autocomplete, FormHelperText, TextField } from "@mui/material";
import { t } from "i18next";
import { get, isEqual } from "lodash";
import { useEffect, useRef, useState } from "react";
import "./BMXAutocomplate.scss";
export default function BMXAutocomplente(props) {
  const {
    meta: { touched, error, submitError },
    input,
    currentValues,
  } = props;

  const prevCurrentValues = useRef();

  const [defaultVal, setDefaultVal] = useState([]);

  // eslint-disable-next-line
  useEffect(() => {
    if (
      !isEqual(
        get(currentValues, input?.name),
        get(prevCurrentValues, input?.name)
      )
    ) {
      setDefaultVal(get(currentValues, input?.name));
    }
    prevCurrentValues.current = currentValues;
  });

  const isError = () => {
    return !!((error || submitError) && touched);
  };

  return (
    <>
      <Autocomplete
        data-cy="autocomplete-select"
        {...props}
        multiple={input?.multiple}
        name={input?.name}
        className="BMXAutocomplate"
        value={defaultVal}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option?.uuid === value?.uuid}
        onChange={(event, option) => input.onChange(option)}
        noOptionsText={t("global:no_values")}
        renderInput={(params) => (
          <TextField
            variant="standard"
            {...params}
            {...props}
            error={isError()}
          />
        )}
      />
      {isError() && (
        <FormHelperText>
          <span className="error text">{error || submitError}</span>
        </FormHelperText>
      )}
    </>
  );
}
